import React from 'react'
import Img from 'gatsby-image';
import Layout from '../components/layout'
import { StaticQuery, graphql } from 'gatsby';
import { fluidImage } from '../fragments';
import Helmet from 'react-helmet'
const ThankYou = () => (
  <StaticQuery
  query={graphql`
    query ThankyouQuery {
      contactImage: file(relativePath: { eq: "mark-autumns-268520-unsplash.jpg" }) {
        ...fluidImage
      }
    }
  `}
  render={data => (
<Layout>
<Helmet>
      <title>Tobias Meixner - Contact me</title>
    </Helmet>
<Img
fluid={data.contactImage.childImageSharp.fluid}
style={{
height: '185px',
}} alt="Bridge" />
  <div className={"quote"}>    
  Opportunities don't happen. You create them.
      <span>Chris Grosser</span> 
    </div>
    <h1 style={{
      textAlign: 'center'
    }}>Thank you</h1>           
  </Layout>
  )} />
)

export default ThankYou
